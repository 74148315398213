import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Card, Button} from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { useContractReader } from "eth-hooks";
import { Address } from '../components';
import { getFromIPFS } from '../helpers/ipfs';
import { IPFS_GATEWAY } from '../constants';

const { ethers } = require("ethers");

function Monk({ readContracts, writeContracts, gasPrice, tx }) {
  const history = useHistory();
  const { tokenID } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [metadata, setMetadata] = useState();
  const [imageURL, setImageURL] = useState();

  const owner = useContractReader(readContracts, 'CryptoMonks', 'ownerOf', [tokenID]);
  const tokenURI = useContractReader(readContracts, 'CryptoMonks', 'tokenURI', [tokenID]);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        console.log('loading metadata');
        const ipfsHash = tokenURI.replace('ipfs://', '')
        const tokenMetadata = await getFromIPFS(ipfsHash);
        const imageURL = JSON.parse(tokenMetadata).image.replace('ipfs://', IPFS_GATEWAY)
        //const imageURL = IPFS_GATEWAY + '/' + imageHash;

        setImageURL(imageURL)
        setMetadata(tokenMetadata);
        setLoaded(true);
      } catch (err) {
        console.error(err);
      }
    };
    if (readContracts.CryptoMonks && tokenURI) { fetchInfo() }
  }, [metadata, readContracts, tokenURI])

  if (!owner) {
    return 'Not Found';
  }

  if (!loaded) {
    return 'Loading...';
  }

  let cardActions;

  const backButton = <div>
    <Button onClick={() => history.push('/gallery') }>Back</Button>
  </div>;

  const ownedBy = <div>
    owned by:{" "}
    <Address
      address={owner}
    />
  </div>;

  return (
    <div>
      <div style={{ maxWidth: 820, margin: "auto", marginTop: 32, paddingBottom: 256 }}>
        { backButton }
        <Card
          style={{ width: 800 }}
          actions={cardActions}
          title={
            <div>
              CryptoMonk #{ tokenID }
            </div>
          }
        >
          <img style={{ maxWidth: 130 }} src={imageURL} alt="" />

          <div>
            { ownedBy }
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Monk;
