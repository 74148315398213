import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Alert, Card, Button} from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { useContractReader } from "eth-hooks";
import { Account, Address } from '../components';

import unrevealed from '../assets/logo.png'

const { ethers } = require("ethers");

function Mint({ address, readContracts, writeContracts, gasPrice, tx, userSigner, mainnetProvider, blockExplorer }) {
  const history = useHistory();

  const price = useContractReader(readContracts, 'CryptoMonks', 'cost')
  const onlyWhitelisted = useContractReader(readContracts, 'CryptoMonks', 'onlyWhitelisted');

  const [isWhitelisted, setIsWhitelisted] = useState();
  const [addressLoaded, setAddressLoaded] = useState(false);
  const [txHash, setTxHash] = useState();

  const isOwner = address && address.toLowerCase() == '0x68E2D04D4cF19F7483e461A202d4a1aE2cC4e466'.toLowerCase()

  useEffect(() => {
    async function loadAddress()  {
      const wl = await readContracts.CryptoMonks.isWhitelisted(address);
      setIsWhitelisted(wl);
      setAddressLoaded(true);
    }
    if (address) { loadAddress() }
  }, [address]);

  useEffect(() => {

    function wait(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function fetchReceipt() {
      try {
        const receipt = await mainnetProvider.getTransactionReceipt(txHash);
        return receipt;
      } catch (err) {
        console.error(err);
      }
    }
    async function redirectOnConfirm()  {
      try {
      let receipt = null;
      do {
        wait(1000);
        receipt = await fetchReceipt();
      } while (receipt === null);

      const monkIdHex = receipt.logs[0].topics[3];
      const monkId = ethers.BigNumber.from(monkIdHex).toString();
      history.push(`/monk/${monkId}`);
      } catch (err) {
        console.error(err)
      }

    }
    if (txHash) { redirectOnConfirm() }
  }, [txHash]);

  if (!price) {
    return 'Loading...';
  }

  const onlyWhitelistAlert = <div style={{ paddingBottom: 10 }}>
    <Alert message="Only whitelisted addresses may mint" type="info" showIcon />
  </div>;
  const youreNotWhitelisted = <div style={{ paddingBottom: 10 }}>
    <Alert message="Your address is not whitelisted" type="warning" showIcon />
  </div>;
  const youAreWhitelisted =  <div style={{ paddingBottom: 10 }}>
    <Alert message="Your address is whitelisted" type="success" showIcon />
  </div>;

  const mintEnabled = (
    ( address && onlyWhitelisted == false ) ||
    ( address && onlyWhitelisted == true && addressLoaded && isWhitelisted) ||
    isOwner
  )

  const actions = [<div>
    { address ? '' : 'Connect your wallet to mint' }
    <br />
    <Button
      disabled={!mintEnabled}
      onClick={() => {
        console.log("gasPrice,", gasPrice);
        //  37000000000
        //  10000000000
        let g = gasPrice + 25000000000;
        let p = isOwner ? 0 : price;
        tx(writeContracts.CryptoMonks.mint(1, { gasPrice: g, value: p }), (result, receipt) => {
          if (result.status === 'confirmed') {
            console.log('Confirmed with hash ', result.hash);
            setTxHash(result.hash);
          }
        });
      }}
    >
      Mint for { ethers.utils.formatEther(price) }Ξ
    </Button>
  </div>]

  let whitelistAlerts = [];
  if (address) {
    if (onlyWhitelisted && !isWhitelisted) whitelistAlerts.push(youreNotWhitelisted)
    if (onlyWhitelisted && isWhitelisted) whitelistAlerts.push(youAreWhitelisted)
  }

  return (
    <div>
      <div style={{ maxWidth: 820, margin: "auto", marginTop: 32, paddingBottom: 256 }}>
        <Card
          style={{ width: 800, border: 0 }}
          title={
            <div>
              Mint a CryptoMonk
            </div>
          }
          actions={actions}
        >
          { onlyWhitelisted ? onlyWhitelistAlert : undefined }

          <div>
            <img width="600"  src={unrevealed} />
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Mint;
