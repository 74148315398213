import { create } from "ipfs-http-client";
import { BufferList } from "bl";
import { INFURA_IPFS_ID, INFURA_IPFS_KEY } from '../constants'

//export const ipfs = create({ host: "ipfs.kos.kozan.xyz", port: "80", protocol: "http" });
//export const ipfs = create({ host: "ipfs.infura.io", port: "5001", protocol: "https" });

const ipfs = create({ host: 'ipfs.infura.io', port: 5001, protocol: 'https' })
//const auth = 'Basic ' + Buffer.from(INFURA_IPFS_ID + ':' + INFURA_IPFS_KEY).toString('base64')
//const client = ipfsClient({
  //host: 'ipfs.infura.io',
  //port: 5001,
  //protocol: 'https',
  //apiPath: '/api/v0/',
  //headers: {
    //authorization: auth
  //}
//})

export async function addToIPFS(file) {
  const fileAdded = await ipfs.add(file);

  return fileAdded;
}

export function urlFromCID(cid) {
  //return `https://ipfs.infura.io/ipfs/${cid}`;
  //return `https://ipfs.kos.kozan.xyz/ipfs/${cid}`;
}

export async function getFromIPFS(hashToGet) {
  for await (const file of ipfs.cat(hashToGet)) {
    const content = new BufferList(file).toString();

    return content;
  }
}
