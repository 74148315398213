import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Input, List, Menu, Row, Pagination } from "antd";
import { Link } from "react-router-dom";
import { LinkOutlined } from "@ant-design/icons";
import StackGrid from "react-stack-grid";
import {
  //useBalance,
  useContractReader,
  //useGasPrice,
} from "eth-hooks";
import { Address } from '../components';
import { getFromIPFS } from '../helpers/ipfs'
import { IPFS_GATEWAY } from '../constants'

//import assets from "../assets.js";

const { ethers } = require("ethers");

function Gallery({ readContracts, targetNetwork, tx, gasPrice, writeContracts, transferEvents  }) {
  const [loadedAssets, setLoadedAssets] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const totalSupply = useContractReader(readContracts, 'CryptoMonks', 'totalSupply');

  const startId = 1 + (currentPage - 1) * perPage; // Tokens start at 1
  const endId = startId + perPage > totalSupply ? totalSupply : startId + perPage;

  console.log(`Total monks: ${totalSupply}`);

  useEffect(() => {
    const updateYourCollectibles = async () => {
      const assetUpdate = [];
      for (let x=startId; x < endId; x++) {

        const tokenURI = await readContracts.CryptoMonks.tokenURI(x);
        console.log(tokenURI);
        const tokenMetadata = JSON.parse(await getFromIPFS(tokenURI.replace('ipfs://','')));
        console.log(tokenMetadata);
        const imageURL = tokenMetadata.image.replace('ipfs://', IPFS_GATEWAY);
        assetUpdate.push({ id: x, imageURL, tokenURI , metadata: tokenMetadata })
      }
      setLoadedAssets(assetUpdate);
    };
    if (readContracts && readContracts.CryptoMonks && totalSupply) updateYourCollectibles();
  }, [readContracts, transferEvents, currentPage, perPage, totalSupply]);

  const galleryList = [];

  //console.log(loadedAssets);

  for (const a in loadedAssets) {
    let cardActions = [];
    galleryList.push(
      <Card
        style={{ width: 200 }}
        key={`monk-${loadedAssets[a].id}`}
        actions={cardActions}
        title={
          <div>
            <Link to={`/monk/${loadedAssets[a].id}`}>Monk {loadedAssets[a].id}{" "}
              <LinkOutlined />
             </Link>
          </div>
        }
      >
        <Link to={`/monk/${loadedAssets[a].id}`}>
          <img style={{ maxWidth: 100 }} src={loadedAssets[a].imageURL} alt="" />
        </Link>
        <div style={{ opacity: 0.77 }}></div>
      </Card>,
    );
  }


    //console.log(a)
    //const cardActions = [];
    //if (loadedAssets[a].forSale) {
      //cardActions.push(
        //<div>
          //<Button
            //onClick={() => {
              //console.log("gasPrice,", gasPrice);
              //tx(writeContracts.CryptoMonks.mintItem(loadedAssets[a].id, { gasPrice }));
            //}}
          //>
            //Mint
          //</Button>
        //</div>,
      //);
    //} else {
      //cardActions.push(
        //<div>
          //owned by:{" "}
          //<Address
            //address={loadedAssets[a].owner}
            //minimized
          ///>
        //</div>,
      //);
    //}

    //galleryList.push(
      //<Card
        //style={{ width: 200 }}
        //key={loadedAssets[a].name}
        //actions={cardActions}
        //title={
          //<div>
            //<Link to={`/monk/${loadedAssets[a].id}`}>{loadedAssets[a].name}{" "}
              //<LinkOutlined />
             //</Link>
          //</div>
        //}
      //>
        //<Link to={`/monk/${assetKeys[a]}`}>
          //<img style={{ maxWidth: 100 }} src={`https://ipfs.io/ipfs/${loadedAssets[a].image}`} alt="" />
        //</Link>
        //<div style={{ opacity: 0.77 }}>{loadedAssets[a].description}</div>
      //</Card>,
    //);
  //}

  const paginationOnChange = (page, pageSize) => {
    //setLoadedAssets(false);
    setPerPage(pageSize);
    setCurrentPage(page);
  }

  const pagination = <div style={{ marginTop: 10, paddingBottom: 10 }}><Pagination  current={currentPage} defaultCurrent={1} defaultPageSize={perPage} total={totalSupply} onChange={paginationOnChange} /></div>

  return (
    <div>
      <div style={{ maxWidth: 1220, margin: "auto", marginTop: 32, paddingBottom: 256 }}>
        { pagination }
        <StackGrid columnWidth={200} gutterWidth={16} gutterHeight={16}>
          {galleryList}
        </StackGrid>
        { pagination }
      </div>
    </div>
  );

}

export default Gallery;
