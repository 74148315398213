import { PageHeader } from "antd";
import React from "react";

import logo from '../assets/logo.png'
// displays a page header

export default function Header() {
  return (
    <a href="https://cryptomonks.app" target="_blank" rel="noopener noreferrer">
      <PageHeader
        title="CryptoMonks"
        subTitle=""
        style={{ cursor: "pointer" }}
      />
    </a>
  );
}
